import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Retrofit integration";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "retrofit-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#retrofit-integration",
        "aria-label": "retrofit integration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Retrofit integration`}</h1>
    <p><a parentName="p" {...{
        "href": "https://square.github.io/retrofit/"
      }}>{`Retrofit`}</a>{` is a library that simplifies the access to RESTful services
by turning an HTTP API into a Java interface.`}</p>
    <p>{`Armeria provides a builder class called `}<a parentName="p" {...{
        "href": "type://ArmeriaRetrofit:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retrofit2/ArmeriaRetrofit.html"
      }}>{`type://ArmeriaRetrofit`}</a>{` that builds an alternative
`}<inlineCode parentName="p">{`Retrofit`}</inlineCode>{` implementation that replaces the networking engine of Retrofit from `}<a parentName="p" {...{
        "href": "https://square.github.io/okhttp/"
      }}>{`OkHttp`}</a>{`
to Armeria. By doing so, you get the following benefits:`}</p>
    <ul>
      <li parentName="ul">{`Better performance, thanks to `}<a parentName="li" {...{
          "href": "https://netty.io/"
        }}>{`Netty`}</a>{` and its JNI-based I/O and TLS implementation`}</li>
      <li parentName="ul">{`Leverage other advanced features of Armeria, such as client-side load-balancing and service discovery`}</li>
      <li parentName="ul">{`Cleartext HTTP/2 support, as known as `}<inlineCode parentName="li">{`h2c`}</inlineCode></li>
    </ul>
    <p>{`First, you need `}<inlineCode parentName="p">{`armeria-retrofit2`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-retrofit2'
    }]} mdxType="RequiredDependencies" />
    <p>{`Now, you can build `}<inlineCode parentName="p">{`Retrofit`}</inlineCode>{` using `}<a parentName="p" {...{
        "href": "type://ArmeriaRetrofit#builder(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retrofit2/ArmeriaRetrofit.html#builder(java.lang.String)"
      }}>{`type://ArmeriaRetrofit#builder(String)`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.retrofit2.ArmeriaRetrofit;

import retrofit2.Retrofit;
import retrofit2.converter.jackson.JacksonConverterFactory;
import retrofit2.http.GET;
import retrofit2.http.Path;

class UserInfo { ... }

interface MyService {
    @GET("/userInfo/{id}")
    CompletableFuture<UserInfo> getUserInfo(@Path("id") String id);
}

Retrofit retrofit = ArmeriaRetrofit.builder("http://localhost:8080/")
                                   .addConverterFactory(JacksonConverterFactory.create())
                                   .build();

MyService service = retrofit.create(MyService.class);
UserInfo userInfo = service.getUserInfo("foo").get();
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retrofit2/package-summary.html"
      }}>{`com.linecorp.armeria.client.retrofit2`}</a>{` package.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      